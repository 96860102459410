<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <img
          :src="config.url"
          style="width: 100%!important;height:auto;cursor:pointer;"
          v-bind="attrs"
          v-on="on"
        />
        <!--v-img
          :src="config.type === 'video' ? config.screenshot : config.url"
          :style="{ height: prevHeight ? `${prevHeight}px` : null }"
          v-bind="attrs"
          v-on="on"
        /-->
        <!--v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          style="margin-left: 10px"
        >
          <v-icon>mdi-motion-play-outline</v-icon>
        </v-btn-->
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
          elevation="0"
          style="border-radius:0"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{cd}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              {{ $t('common.close', locale)}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row no-gutters>
          <v-col
            cols="12"
            xs="12"
            style="padding-top: 20px;"
          >
            <center>
              <v-card
                v-if="size && content"
                elevation="2"
                class="main-template-preview" 
                :style="{ width: `${size.width}px`, height: `${size.height}px` }"
              >
                <div
                  v-html="content.content"
                />
              </v-card>
            </center>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
export default {
  components: {
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    prevHeight: {
      type: Number,
      required: true,
    },
    cd: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    size: null,
    content: null,
  }),
  computed: {
    url () {
      return this.config.url
    },
  },
  watch: {
    'config.url' () {
      this.handlePrepare()
    },
    dialog () {
      this.handlePrepare()
    },
  },
  mounted () {
    this.handlePrepare()
  },
  methods: {
    async handleGetSize () {
      return this.scaleSize(this.config)
    },
    prepareMediaContent (type, id, name, url, description, duration) {
      if (!duration || duration.length === 0) duration = 1
      const content = type === 'video' ? `<video id="${id}" src="${url}" width="100%" height="100%" loop
            autoplay="autoplay" muted defaultmuted playsinline />`
        : `<img src="${url}" width="100%" height="100%">`

      return {
        content,
        duration,
        type,
        id,
        name,
      }
    },

    async handlePrepare () {
      this.content = null
      if (!this.dialog) return
      if (!this.url || this.url.length < 4) return
      this.size = await this.handleGetSize()
      if (!this.size) {
        alert(' URL no permitida...')
        return
      }
      
      this.content = this.prepareMediaContent(this.config.type, uuidv4(), '',  this.url, '', 10000)
    },
    scaleSize (v, maxH) {
      const maxHeight = maxH ? maxH : 450
      if (v.height > maxHeight) {
        v.width = v.width * maxHeight / v.height
        v.height = maxHeight
      }
      return v
    },
    handleClose () {
      this.xml = null
      this.showError = false
    },
  },
}
</script>

<style>
* {
  font-family: 'Roboto';
}
</style>



